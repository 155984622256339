.container {
  background: #007DFF;
  border-radius: 5px;
}

.btnTrending {  
  background: #D32C1F;
  border-radius: 3px;
  font-weight: 600;
  font-size: 10px;
  color: #F5F5F5;
  text-transform: uppercase;
  margin-left: 10px;
}

.action {
  padding-left: 0;
}

.profile {
  border-radius: 50%;
  padding: 3px;
  margin-right: 10px;
  border: 2px solid #FFFFFF;
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}