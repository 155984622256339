.welcomeSection {
  background-color: white;
  padding: 0 16px;
  padding-top: 85px;

  @media only screen and (max-width: 728px) {
    padding-top: 160px;
  }
}

.modalImage {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-62%, -50%);
  width: 300px;
  background-color: transparent;
  outline: 0;
  margin-right: 3px;
}

.welcomeHeader {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 80px;
}

.welcomeHeaderContainer {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;

  @media screen and (max-width: 1100px) {
    flex-direction: column !important;
  }
}

.welcomeText {
  flex: 1;
  font-size: 2.5rem;
  font-weight: 700;
  color: #0e1548;
  width: 100%;
}

.appIconsContainer {
  margin-left: auto;
  display: flex;
  z-index: 10;

  @media screen and (max-width: 1100px) {
    flex-direction: column;
  }

  @media screen and (max-width: 768px) {
    flex-direction: row;
    justify-content: center;
    margin: 10px 0px;
  }
}

.playStoreBtn {
  width: 150px;
  height: auto;
}

.appStoreBtn {
  width: 150px;
  height: auto;
  margin-left: 10px;

  @media screen and (max-width: 1100px) {
    margin-left: 0px;
  }

  @media screen and (max-width: 768px) {
    margin-left: 10px;
  }
}

.welcomeDescription {
  padding: 0.5rem 25vw;
  color: #0e1548;
  font-weight: 700;
}

.mwebWelcomeContent {
  display: none;
}

.webWelcomeContent {
  padding: 2rem 0;
  width: 100vw;
  // background: yellow;
  display: flex;
  justify-content: center;
  height: 100%;
}

.outerCircle {
  height: 500px;
  width: 500px;
  background: white;
  border: 2px dashed #0088fd;
  border-radius: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.middleCircle {
  height: 400px;
  width: 400px;
  background: #72bdff;
  border-radius: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.innerCircle {
  height: 320px;
  width: 320px;
  background: #0088fd;
  border-radius: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main_img {
  width: 250px;
}

.optionBtn {
  background: transparent;
  border: none;
}

.options {
  height: 450px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-self: center;
  max-width: 400px;
}

.option {
  display: flex;

  div {
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    align-items: center;

    div {
      display: flex;
      gap: 0rem;
      flex-direction: column;
      align-content: space-between;
      align-items: flex-start;
    }
  }
}

.left {
  position: relative;
  right: -45px;
  text-align: right;

  .option {
    justify-content: flex-end;
  }

  div {
    align-items: flex-end !important;
  }
}

// .left > .second {
//   right: -80px;
//   position: relative;
// }
.left > .first,
.left > .third {
  position: relative;
  right: -55px;
}

.right {
  position: relative;
  left: -45px;
  text-align: left;

  .option {
    justify-content: flex-start;
  }
}

.right > .first,
.right > .third {
  position: relative;
  left: -55px;
}

.featureTitle {
  font-weight: 500;
  font-size: 1.15rem;
  color: #000000;
}

.featureContainer {
  width: 250px;
  padding: 0 0 1rem 0;
  text-align: center;
}

.featureDescription {
  color: #707070;

  p {
    margin-bottom: 0;
  }
}

.innerCircle {
  position: relative;

  img {
    position: absolute;
    width: 250px;
    z-index: 3;
  }

  div {
    width: 230px;
    height: 464px;
    background-color: white;

    video {
      bottom: 0.25px;
      width: 230px;
      z-index: 2;
      border-radius: 32px;
    }
  }
}

@media only screen and (max-width: 1240px) {
  .options {
    height: 325px;
  }

  .welcomeHeaderContainer {
    flex-direction: column;
  }

  .availablePlatform {
    display: none;
  }

  .outerCircle {
    height: 350px;
    width: 350px;
  }

  .middleCircle {
    height: 250px;
    width: 250px;
  }

  .innerCircle {
    height: 180px;
    width: 180px;

    img {
      width: 150px;
    }

    div {
      width: 138px;
      height: 284px;
      background-color: white;

      video {
        width: 138px;
        z-index: 2;
        border-radius: 16px;
      }
    }
  }

  .left {
    right: -25px;
  }

  .right {
    left: -25px;
  }

  .left > .first,
  .left > .third {
    // right: -35px;
  }

  .right > .first,
  .right > .third {
    // left: -35px;
  }
}

@media only screen and (max-width: 1024px) {
  .modalImage {
    transform: translate(-50%, -50%);
  }

  .webWelcomeContent {
    display: none;
  }

  .mWebWelcomeContainer {
    margin-top: -50px;
  }

  .mwebWelcomeContent {
    display: flex;
    flex-direction: row;
    justify-content: center;
    transform: scale(0.8);
  }

  .mwebVideoContainerBorder {
    border: 2px dashed #0088fd;
    padding: 20px 50px 20px;
    border-radius: 20px;
    border-top-right-radius: 40px;
    border-top-left-radius: 40px;
    border-bottom-left-radius: 25px;
  }

  .mwebVideoContainer {
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    img {
      position: absolute;
      width: 250px;
      z-index: 3;
    }

    div {
      width: 230px;
      height: 464px;
      background-color: white;

      video {
        bottom: 0.25px;
        width: 230px;
        z-index: 2;
        border-radius: 32px;
      }
    }
  }

  .videoActions {
    display: flex;
    overflow-x: auto;
    justify-content: space-between;
    padding: 1rem 2rem;

    button {
      background: transparent;
      border: none;
    }
  }
}

@media only screen and (max-width: 728px) {
  .welcomeText {
    font-size: 1.75rem;
  }

  .welcomeDescription {
    padding: 0 1rem;
  }

  .shadow img {
    border-radius: 10px;
  }
}

.verticalButtons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  img {
    width: 48px;
    background: white;
  }

  button {
    border: none;
  }
}

.verticalRightButtons {
  position: relative;
  left: -24px;
  z-index: 1;
}

.verticalLeftButtons {
  position: relative;
  right: -24px;
  z-index: 1;
}

.iconsRow {
  display: flex;
  flex-direction: row !important;
  justify-content: center;

  img {
    height: 1.2rem;
    width: 1.2rem;
    margin-right: 0.5rem;
  }
}

.shadow {
  border-radius: 10px;

  img {
    border-radius: 8px;
  }

  animation: pulse-animation1 2s infinite;
}

@keyframes pulse-animation1 {
  0% {
    box-shadow: 0px 0px 0px #70b7f6;
  }

  100% {
    box-shadow: 0 0 15px 15px #0088fd;
  }
}

.shadow2 {
  border-radius: 10px;

  img {
    border-radius: 8px;
  }

  animation: pulse-animation2 2s infinite;
}

@keyframes pulse-animation2 {
  0% {
    box-shadow: 0 0 0 0px #fea8bb;
    // border: 3px solid #fea8bb;
  }

  100% {
    box-shadow: 0 0 0 15px rgba(0, 0, 0, 0);
    // border: 3px solid #fea8bb;
  }
}

.shadow3 {
  border-radius: 10px;

  img {
    border-radius: 8px;
  }

  animation: pulse-animation3 2s infinite;
}

@keyframes pulse-animation3 {
  0% {
    box-shadow: 0 0 0 0px #fd897f5e;
    // border: 3px solid #fd897f5e;
  }

  100% {
    box-shadow: 0 0 0 15px rgba(0, 0, 0, 0);
    // border: 3px solid #fd897f5e;
  }
}

.shadow4 {
  border-radius: 10px;

  img {
    border-radius: 8px;
  }

  animation: pulse-animation4 2s infinite;
}

@keyframes pulse-animation4 {
  0% {
    box-shadow: 0 0 0 0px #a5b96396;
    // border: 3px solid #a5b96396;
  }

  100% {
    box-shadow: 0 0 0 15px rgba(0, 0, 0, 0);
    // border: 3px solid #a5b96396;
  }
}

.shadow5 {
  border-radius: 10px;

  img {
    border-radius: 8px;
  }

  animation: pulse-animation5 2s infinite;
}

@keyframes pulse-animation5 {
  0% {
    box-shadow: 0 0 0 0px #fac0668c;
    // border: 3px solid #fac0668c;
  }

  100% {
    box-shadow: 0 0 0 15px rgba(0, 0, 0, 0);
    // border: 3px solid #fac0668c;
  }
}

.shadow6 {
  border-radius: 10px;

  img {
    border-radius: 8px;
  }

  animation: pulse-animation6 2s infinite;
}

@keyframes pulse-animation6 {
  0% {
    box-shadow: 0 0 0 0px #f16da387;
    // border: 3px solid #f16da387;
  }

  100% {
    box-shadow: 0 0 0 15px rgba(0, 0, 0, 0);
    // border: 3px solid #f16da387;
  }
}

.shadow7 {
  border-radius: 10px;

  img {
    border-radius: 8px;
  }

  animation: pulse-animation7 2s infinite;
}

@keyframes pulse-animation7 {
  0% {
    box-shadow: 0 0 0 0px #45e6a982;
    // border: 3px solid #45e6a982;
  }

  100% {
    box-shadow: 0 0 0 15px rgba(0, 0, 0, 0);
    // border: 3px solid #45e6a982;
  }
}

.shadow8 {
  border-radius: 10px;

  img {
    border-radius: 8px;
  }

  animation: pulse-animation8 2s infinite;
}

@keyframes pulse-animation8 {
  0% {
    box-shadow: 0 0 0 0px #86d4afc2;
    // border: 3px solid #86d4afc2;
  }

  100% {
    box-shadow: 0 0 0 15px rgba(0, 0, 0, 0);
    // border: 3px solid #86d4afc2;
  }
}

.noshadow {
  box-shadow: none;
  border-radius: 0px;
}

// video::-webkit-media-controls {
//   opacity: 0;
//   display: none;
// }

.mwebVideoContainer .videoMuteImage,
.innerCircle .videoMuteImage {
  z-index: 99;
  height: 30px;
  width: 30px;
  bottom: 10px;
  right: 15px;
  cursor: pointer;
  border-radius: 8px;
}
