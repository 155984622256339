.container {
  width: 100%;

  @media only screen and (max-width: 767px) {
    overflow-x: hidden;
  }
  font .introWrapper {
    margin-top: 6rem;
    display: flex;

    @media only screen and (max-width: 767px) {
      flex-direction: column;
      margin-top: 0;
    }

    .intro {
      display: flex;
      flex-direction: column;
      padding: 1rem 4rem;
      align-items: center;

      @media only screen and (max-width: 767px) {
        padding: 1rem;
      }

      .brangLogo {
        background: transparent;
        display: flex;
        align-items: center;
        margin-bottom: 1rem;

        @media only screen and (max-width: 767px) {
          flex-direction: column;
          margin-bottom: 1rem;
        }

        .logo {
          height: 10rem;

          @media only screen and (max-width: 767px) {
            margin-right: 0;
          }
        }
      }

      .bannerText {
        font-size: 1.75rem;
        // font-weight: 600;
        color: var(--text-secondary-color);
        text-align: start;

        @media only screen and (max-width: 767px) {
          color: var(--text-default-color);
          font-size: 1.75rem;
          text-align: center;
        }

        @media only screen and (max-width: 1024px) {
          color: var(--text-default-color);
          font-size: var(--lg-font);
          text-align: center;
        }
      }
    }

    .preview {
      display: flex;
      justify-content: center;
      margin: 0 96px 0 32px;

      @media only screen and (max-width: 767px) {
        margin: 0 32px 0 32px;
      }

      img {
        height: 448px;
      }

      @media only screen and (max-width: 767px) {
        img {
          height: unset;
          width: 80%;
        }
      }
    }
  }

  .summaryWrapper {
    margin-top: -2rem;
    padding: 8rem 0 5rem 0;
    text-align: center;
    min-height: 560px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .incomOpportunitiesWrapper {
    margin-top: -2rem;
    text-align: center;
    padding-top: 8rem;
    min-height: 560px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .incomOpportunitiesHeading {
      font-size: var(--xl-font);
    }

    .incomOpportunities {
      display: flex;
      justify-content: center;
      align-items: flex-start;

      div {
        flex: 1;
      }

      @media only screen and (max-width: 767px) {
        flex-direction: column;
        align-items: center;
      }

      .incomeOpportunity {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-bottom: 2rem;

        .incomeOpportunityIcon {
          height: 5rem;
          width: 5rem;
        }

        .incomeOpportunityName {
          font-weight: bold;
          font-size: var(--lg-font);
        }

        .incomeOpportunityDescription {
          font-size: var(--md-font);
          max-width: 90%;
        }
      }

      .summaryHeader {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-bottom: 2rem;

        @media screen and (min-width: 800px) {
          width: 25%;
        }

        .incomeOpportunityIcon {
          height: 5rem;
          width: 5rem;
        }

        .incomeOpportunityName {
          font-weight: bold;
          font-size: var(--lg-font);
        }

        .incomeOpportunityDescription {
          font-size: var(--md-font);
          max-width: 100%;
        }
      }
    }

    h2 {
      margin: 0 25px;
      font-size: 32px;
    }
  }

  .referredBy {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    position: relative;
    flex-direction: column;

    .referrerBadge {
      background-color: #c5defb !important;
      text-align: center;
      padding: 8px 32px;

      @media only screen and (max-width: 767px) {
        box-shadow: none !important;
      }

      .referrerHeading {
        font-size: var(--md-font);
        font-weight: 600;
      }

      .referredByHeading {
        font-size: var(--sm-font);
        font-weight: 600;
      }
    }
  }

  .referredBy::before {
    position: absolute;
    left: 0;
    top: -100px;
    content: url('../../../assets/svg/group-31.svg');
    z-index: -1;
  }

  .referredBy::after {
    position: absolute;
    right: 0;
    top: 0;
    content: url('../../../assets/svg/group-32.svg');
    z-index: -1;
  }

  .screenshotWrapper {
    padding: 4rem 1rem 1rem 1rem;
    background-color: transparent;

    @media only screen and (max-width: 767px) {
      background-color: rgb(12, 23, 46);
    }

    .corousalImage {
      width: calc(100% - 7rem);
      margin: 0.5rem;
      border-radius: 10px;
    }

    @media only screen and (max-width: 767px) {
      .corousalImage {
        width: calc(100% - 2rem);
      }
    }
  }

  .faqsWrapperMobile {
    padding: 1rem;
  }

  .accordionDetails {
    max-height: 400px;
    overflow-y: scroll;
  }

  .faqsWrapper {
    padding: 4rem;

    @media only screen and (max-width: 767px) {
      padding: 2rem 1rem;
    }

    .faqsHeading {
      font-size: var(--xl-font);
      text-align: center;
    }

    .faq {
      background-color: #eff2fe;
      margin-bottom: 1rem;
      border-radius: 4px;
      box-shadow: none;

      .faqHeading {
        font-size: var(--lg-font);

        @media only screen and (max-width: 767px) {
          font-size: var(--md-font);
        }
      }

      .faqAnswerContainer {
        padding: 1rem;
        background-color: var(--theme-light-color);

        .faqAnswer {
          text-align: start;
          font-size: 1.25rem;

          @media only screen and (max-width: 767px) {
            font-size: var(--md-font);
          }

          ul {
            margin: 0;
          }
        }
      }
    }
  }

  .gettingStartedWrapper {
    margin-top: 0rem;
    text-align: center;
    background-color: #f5f5f5;
    padding: 3rem 2rem 1rem 2rem;

    @media only screen and (max-width: 1480px) {
      padding: 3rem 2rem 1rem 2rem;
    }

    @media only screen and (max-width: 767px) {
      padding: 2rem 1rem;
    }

    .gettingStartedHeading {
      font-size: var(--xl-font);
      margin-bottom: 2rem;
      font-weight: bold;

      @media only screen and (max-width: 767px) {
        font-size: 25px;
      }
    }

    .content {
      font-size: var(--lg-font);

      @media only screen and (max-width: 767px) {
        font-size: var(--md-font);
      }
    }

    .actionButton {
      margin-bottom: 2rem;
      width: 150px;
      border-radius: 25px;
      border: 2px solid;
    }
  }

  .footer {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 48px;
    max-height: 75px;
    font-size: var(--md-font);
    font-weight: bold;
    text-align: center;
    box-shadow: 0 2px 6px 0 rgb(0, 0, 0 / .15);

    @media only screen and (max-width: 567px) {
      flex-direction: column;
    }

    span {
      display: flex;

      @media only screen and (max-width: 567px) {
        padding-bottom: 6px;

        .hidden {
          display: none;
        }
      }
    }

    p {
      margin: 0 8px;

      a {
        color: var(--text-default-color) !important;
      }
    }

    @media only screen and (max-width: 567px) {
      font-size: var(--sm-font);

      p {
        margin: 0 4px;
      }
    }

    @media only screen and (max-width: 385px) {
      font-size: 0.6rem;
    }
  }
}

.desktop {
  display: initial;

  @media only screen and (max-width: 767px) {
    display: none !important;
  }
}

.mobile {
  display: none !important;

  @media only screen and (max-width: 767px) {
    display: initial !important;
  }
}

.IPhone8SketBoardPreview {
  margin: auto;
  width: 50%;

  @media only screen and (max-width: 767px) {
    width: 70%;
  }
}

.sidePanelContainer {
  width: 100%;
  height: 100%;
  background-color: white;

  .sidePanelHeader {
    padding: 0 16px;
    height: 64px;
    display: flex;
    justify-content: space-between;
    background-color: white;

    @media only screen and (max-width: 767px) {
      padding: 0;
    }

    .sidePanelHeading {
      flex: 1;
      font-weight: 600;
      font-size: var(--lg-font);
      color: var(--text-primary-color);
      margin: auto 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .sidePanelLinks {
    height: calc(100vh - 64px);
    overflow-y: auto;

    a {
      text-decoration: none;
    }

    .link {
      padding: 16px;
      font-weight: 600;
      font-size: var(--md-font);
      color: var(--text-default-color);
      border-bottom: 1px solid rgb(213, 213, 213);
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    .active {
      border: none;
      background-color: var(--theme-primary-color);
      color: white;
    }

    .subMenu {
      padding-left: 48px;
    }

    g & .active {
      stroke: white !important;
    }

    path & .active {
      stroke: white !important;
      fill: white !important;
    }

    .icon {
      margin-right: 16px;
      fill: var(--text-default-color);
      font-size: 1.25rem;

      g {
        stroke: var(--text-default-color);
      }
    }
  }
}

.sideNavActionButton {
  margin: 0;
}

.sideNavActionButton:first-child {
  margin-right: 8px;
}

.sideNavActionButton:last-child {
  margin-left: 8px;
}

.loaderContainer {
  z-index: 9999 !important;
}

.menuPopper {
  top: 64px !important;
  position: relative;
  width: 50%;
}

.menuPopper::before {
  content: url('../../../assets/svg/arrow_drop_up_white_24dp.svg');
  width: 16px;
  height: 16px;
  z-index: 999999;
  position: absolute;
  top: -16px;
  left: 24px;
}

.menuItem {
  font-weight: 500;
  font-size: var(--md-font);
}

.menuItem:not(:last-child) {
  border-bottom: 1px solid rgb(225, 225, 225);
}

.linkClass {
  cursor: pointer;
}

.faqsWrapper {
  background-color: #f5f5f5;
  background-color: #f5f5f5;
  padding-left: 10%;
  padding-right: 10%;

  .containerFAQs {
    display: flex;
    margin-top: 40px;
    height: 500px;
    padding-left: 10%;
    padding-right: 10%;

    .leftSection {
      width: 40%;
      overflow-y: scroll;

      .list {
        .line {
          width: 4px;
          background-color: #1578fc;
          display: none;
        }

        .show {
          display: block;
        }

        .item {
          padding: 10px;
          display: flex;
          align-items: center;
          width: 100%;
        }

        display: flex;
        height: 80px;
      }
    }

    .rightSection {
      background-color: white;
      width: 60%;
      padding: 5%;
      padding-top: 2%;
      overflow-y: scroll;

      h6 {
        line-height: 2rem;
      }
    }
  }
}
