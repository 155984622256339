.container {
  padding: 4rem;
  padding-left: 10%;
  padding-right: 10%;
  background-color: #deeeff;

  ul {
    margin-bottom: 50px;
    font-size: 18px;
  }

  @media screen and (max-width: 786px) {
    padding-left: 5%;
    padding-right: 5%;
  }
  h2 {
    text-align: center;
    font-weight: 700;
    margin-bottom: 40px;
    font-size: 40px;
    color: #00162f;
  }

  .box {
    background-color: white;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;

    p {
      margin-bottom: 5px;
    }
  }
  .boxTitle {
    font-weight: bold;
    font-size: 20px;
  }
  .FirstBox {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    width: 80px;
    border-radius: 8px;
    background-image: linear-gradient(to right, #ffd9a8, #ff8379);

    img {
      width: 50px;
      height: 50px;
    }
  }
}
